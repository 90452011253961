import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { StorageService } from '../storage.service';
import { Constants } from "../helper/constants";
import * as moment from 'moment';
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private storageService: StorageService) { }

    public isAuthenticated(): boolean {
        // get the token
        const helper = new JwtHelperService();
        const token = this.storageService.getToken();
        const sessionId = this.storageService.getSessionId();
        if(token === undefined || token === null || token === "") {
            return false;
        } else if(sessionId === undefined || sessionId === null || sessionId === "")  {
            return false;
        } else {
            // if(helper.isTokenExpired(token)) {
            //     return false;
            // } 
            if(this.isSessionValid() === false){
                return false;
            } else {
                return true;
            }
        }        
    }

    private isSessionValid(): boolean {
        if (Constants.lastAccessDateInEpoch === 0 || Constants.lastAccessDateInEpoch === undefined || Constants.lastAccessDateInEpoch === NaN) {
            Constants.lastAccessDateInEpoch = this.getTimeSinceEpoch();
            let lastAccessedDate = moment(Constants.lastAccessDateInEpoch * 1000).format('YYYY-MM-DD hh:mm:ss A');
            return true;
        } else {
            // let lastAccessedDate = moment(Constants.lastAccessDateInEpoch * 1000).format('YYYY-MM-DD hh:mm:ss A');
            // let differenceInSeconds = moment().diff(lastAccessedDate, 'seconds');
            // if (differenceInSeconds > environment.sessionTimeoutInSeconds) {
            //     return false;
            // }
            Constants.lastAccessDateInEpoch = this.getTimeSinceEpoch();
            return true;
        }
    }

    private getTimeSinceEpoch(): number {
        const now = new Date()
        const secondsSinceEpoch = Math.round(now.getTime() / 1000)
        return secondsSinceEpoch;
    }
}
