import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private http: HttpClient){

    }



    public Delete(url) {
        let promise = new Promise((resolve, reject) => {
            this.http.delete(url)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['error'],status:err['status']
                })
            });
        });
        return promise;
    }


    public Patch(url, data) {
        let promise = new Promise((resolve, reject) => {
            this.http.patch(url,data)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['error'],status:err['status']
                })
            });
        });
        return promise;
    }

    public Put(url, data) {
        let promise = new Promise((resolve, reject) => {
            this.http.put(url,data)
                .toPromise()
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject(res)
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    public Post(url, data) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(url,data)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err.message
                })
            });
        });
        return promise;
    }

    public Get(url) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(url)
                .toPromise()
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject(res)
                        }
                    }
                ).catch(err=>{

                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }





}
