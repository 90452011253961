import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {HttpService} from "./http.service";
import { UserAuthenticationService } from './user-authentication-service';
import {Project} from '../../models/project'

@Injectable({
    providedIn: 'root'
})
export class BoUsersService {
    apis: Object = {};
    api_domain: string;
    api_bo: string;
    constructor(private httpService: HttpService
        , private apiService:UserAuthenticationService){

        this.api_domain = environment.api_domain;
        this.api_bo = environment.BO_api;
        this.apis = {
            'list': '/BOUsers',
            'create': '/BOUsers',
            'edit': '/BOUsers',
            'delete': '/BOUsers',
            'filter':'/BOUsers/filter',
            'userinfo':'/BOUsers/UserInfo',
            'getActiveCoaches':'/BOUsers/Coaches',            
            'getUserProjects': '/BOUsers/UserProjects',
            'addUserProjects': '/BOUsers/UserProject',
            'getFieldUsers':'/BOUsers/FieldUsers', 
            'getManagementUsers':'/BOUsers/ManagementUsers', 
            'getActiveUsers':'/BOUsers/ActiveUsers', 
        };
    }


    private _getApiUrl(name):string {
        return this.api_domain + this.apis[name];
    }


    add(userData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('create'), userData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    delete(username:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Delete(this._getApiUrl('delete')+'/'+username)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }


    edit(username:string,userData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Patch(this._getApiUrl('edit')+'/'+username, userData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }

    getAll(pageNumber:number, itemsPerPage:number, roleId:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('list')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+roleId)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    filter(pageNumber:number,itemsPerPage:number,filter:any, roleId:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('filter')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+encodeURIComponent(filter)+'/'+roleId)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getUserInfo(username:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('userinfo')+'/'+username)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getActiveCoaches(pageNumber:number, itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getActiveCoaches')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getUserProjects(pageNumber:number,itemsPerPage:number,userName:string) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getUserProjects')+'/'+(pageNumber+1)+'/'+itemsPerPage+'/'+userName)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    addUserProjects(userName:string, projectData:any) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Put(this._getApiUrl('addUserProjects')+'/'+userName, projectData)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err => {
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;

    }

    getFieldUsers(pageNumber:number, itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getFieldUsers')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getManagementUsers(pageNumber:number, itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getManagementUsers')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }

    getActiveUsers(pageNumber:number, itemsPerPage:number) {
        let promise = new Promise((resolve, reject) => {
            this.httpService.Get(this._getApiUrl('getActiveUsers')+'/'+(pageNumber+1)+'/'+itemsPerPage)
                .then(
                    res => { // Success
                        if (res['errorMessage'] === "" && res['code'] == 0) {
                            resolve(res);
                        } else {
                            reject({
                                error:"Error received On Response :"+res['errorMessage'],status:res['code']
                            })
                        }
                    }
                ).catch(err=>{
                reject({
                    error:"Error received :"+err['errorMessage'],status:err['code']
                })
            });
        });
        return promise;
    }
}
