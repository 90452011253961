import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor() { }

    public setStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getStorage(key:string): string {
        let value = localStorage.getItem(key);
        return value;
    }

    public getToken(): string {
        let sessionId = localStorage.getItem('sessionId');
        if(sessionId){
            // get the sessionId..
            let splitted = sessionId.split(":");
            let token = splitted[1];
            return token;
        }
        return "";
    }

    public getSessionId(): string {
        let sessionId = localStorage.getItem('sessionId');
        return sessionId;
    }
    public setSessionId(token: string): void {
        localStorage.setItem('sessionId', token);
    }

    public getRooms(): string {
        let rooms = localStorage.getItem('rooms');
        return rooms;
    }

    public setRooms(rooms: string): void {
        localStorage.setItem('rooms', rooms);
    }

    public setUser(userName: string): void {
        localStorage.setItem('currentUser', userName);
    }

    public getUser(): string {
        return localStorage.getItem('currentUser');
    }

    public setDeniedPageName(accessName: string): void {
        localStorage.setItem('pageDenied', accessName);
    }

    public getDeniedPageName(): string {
        return localStorage.getItem('pageDenied');
    }


}
