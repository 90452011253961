import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router"
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();
import * as moment from 'moment';
import { diff } from "ngx-bootstrap/chronos/moment/diff";
import { UserAuthenticationService } from "../api/user-authentication-service";
import { StorageService } from "../storage.service";
import { environment } from "../../../../environments/environment";
import { Constants } from "../helper/constants";



@Injectable({
    providedIn: 'root'
})
export class SMJwtInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, public router: Router, public userAuthService: UserAuthenticationService, private storageService: StorageService) { }

    private getTimeSinceEpoch(): number {
        const now = new Date()
        const secondsSinceEpoch = Math.round(now.getTime() / 1000)
        return secondsSinceEpoch;
    }

    private isSessionValid(): boolean {
        if (Constants.lastAccessDateInEpoch === 0 || Constants.lastAccessDateInEpoch === undefined || Constants.lastAccessDateInEpoch === NaN) {
            Constants.lastAccessDateInEpoch = this.getTimeSinceEpoch();
            let lastAccessedDate = moment(Constants.lastAccessDateInEpoch * 1000).format('YYYY-MM-DD hh:mm:ss A');

            return true;
        } else {
            // let lastAccessedDate = moment(Constants.lastAccessDateInEpoch * 1000).format('YYYY-MM-DD hh:mm:ss A');
            // let differenceInSeconds = moment().diff(lastAccessedDate, 'seconds');
            // if (differenceInSeconds > environment.sessionTimeoutInSeconds) {
            //     return false;
            // }
            Constants.lastAccessDateInEpoch = this.getTimeSinceEpoch();
            return true;
        }
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

                // check if its expiring.. if it is expired, try to refresh and store..
                if (request.url.indexOf("/Authentication") < 0 && request.url.indexOf("/Refresh-Token") < 0 && request.url.indexOf("/auth/login")<0) {

                    let token = this.storageService.getToken();
                    // console.log("Token -> "+token);
                    const decodedToken = helper.decodeToken(token);
                    const expirationDate = helper.getTokenExpirationDate(token);
                    // console.log("Token expiration data is :"+expirationDate);
                    // const isExpired = helper.isTokenExpired(token);
                    // if (isExpired) {
                    //     // if expired then redirect to login..
                    //     this.router.navigate(["/auth/login"]);
                    // }
                    if (this.isSessionValid() === false) {
                        // expire the session
                        this.storageService.setSessionId("");
                       this.router.navigate(["/auth/login"]);
                    }


                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // redirect to login as an auth error has happened..
                    this.router.navigate(["/auth/login"]);
                }
                if (err.status === 404) {
                    // redirect to not found as an error has happened..
                    //this.router.navigate(["/pages/notfound"]);
                }
                if (err.status === 403) {
                    this.router.navigate(['/403'], { queryParams: { accessName: err.error.apiName } });
                }
            }
        }));
    }
}


