<button class="p-link layout-topbar-button" (click)="userprofile.toggle($event)">
    <i class="pi pi-user"></i>
    <span>Profile</span>
</button>

<p-overlayPanel #userprofile [showTransitionOptions]="'0ms'" [showCloseIcon]="false" [style]="{width: '300px'}">
    <ng-template pTemplate>            
        <div class="flex mb-3 surface-card shadow-2 border-round" [style]="{height: '100px'}">
            <div>
                <p-avatar label="{{userInfo.userInitials}}" styleClass="mr-2" size="xlarge" [style]="{ 'background-color': '#2196F3', color: '#ffffff' }" shape="circle"></p-avatar>
            </div>
            <div>
                <div style="font-size: 1.125em;"><b>{{userInfo.firstName}}&nbsp;{{userInfo.lastName}}</b></div>
                <div>{{userInfo.email}}</div>
                <div><br><i class="pi pi-sign-out"></i>&nbsp;&nbsp;<a href="#" (click)="onSignout()">Sign out</a></div>
            </div>            
        </div>
    </ng-template>
</p-overlayPanel>