import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './backoffice/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [
                    { path: '', loadChildren: () => import('./backoffice/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'uikit', loadChildren: () => import('./backoffice/components/uikit/uikit.module').then(m => m.UikitModule) },
                    { path: 'utilities', loadChildren: () => import('./backoffice/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    { path: 'documentation', loadChildren: () => import('./backoffice/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    { path: 'blocks', loadChildren: () => import('./backoffice/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    { path: 'pages', loadChildren: () => import('./backoffice/components/pages/pages.module').then(m => m.PagesModule) },
                    { path: 'projects', loadChildren: () => import('./backoffice/components/projects/projects.module').then(m => m.ProjectsModule) },
                    { path: 'surveyors', loadChildren: () => import('./backoffice/components/surveyors/surveyors.module').then(m => m.SurveyorsModule) },
                    { path: 'users', loadChildren: () => import('./backoffice/components/users/users.module').then(m => m.UsersModule) },
                    { path: 'area', loadChildren: () => import('./backoffice/components/area/area.module').then(m => m.AreaModule) },
                    { path: 'lookup', loadChildren: () => import('./backoffice/components/lookup/lookup.module').then(m => m.LookupModule) },
                    { path: 'access', loadChildren: () => import('./backoffice/components/access/access.module').then(m => m.AccessModule) },
                ],
            },
            { path: 'auth', loadChildren: () => import('./backoffice/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'landing', loadChildren: () => import('./backoffice/components/landing/landing.module').then(m => m.LandingModule) },
            { path: 'pages/notfound', component: NotfoundComponent },
            { path: '**', redirectTo: 'pages/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
