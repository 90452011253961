import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './backoffice/components/notfound/notfound.component';
import { ProductService } from './backoffice/service/product.service';
import { CountryService } from './backoffice/service/country.service';
import { CustomerService } from './backoffice/service/customer.service';
import { EventService } from './backoffice/service/event.service';
import { IconService } from './backoffice/service/icon.service';
import { NodeService } from './backoffice/service/node.service';
import { PhotoService } from './backoffice/service/photo.service';
import { TokenInterceptor } from './backoffice/service/auth/token.interceptor';
import { SMJwtInterceptor } from './backoffice/service/auth/smjwt.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MessageService} from "primeng/api";
@NgModule({
    declarations: [
        AppComponent, NotfoundComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        MessagesModule,
        MessageModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SMJwtInterceptor,
            multi: true
        },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService,MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
