import { Component, OnInit } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { BoUserInfo } from 'src/app/backoffice/models/bo-user-info';
import {BoUsersService} from "src/app/backoffice/service/api/bo-users-service";
import { StorageService } from 'src/app/backoffice/service/storage.service';
import { AuthService } from 'src/app/backoffice/service/auth/auth.service';
import { Router } from "@angular/router"

@Component({
    selector: 'user-profile',
    templateUrl: './user.profile.component.html',
    styles: [`
        :host ::ng-deep  .p-frozen-column {
            font-weight: bold;
        }

        :host ::ng-deep .p-datatable-frozen-tbody {
            font-weight: bold;
        }

        :host ::ng-deep .p-progressbar {
            height:.5rem;
        }
        :host ::ng-deep .p-message {
            margin-left: .25em;
        }

        :host ::ng-deep .p-toast{
            z-index:99999;
        }

        a { text-decoration: none; }
    `]
})
export class UserProfileComponent  implements OnInit {

    userInfo:BoUserInfo;
    
    constructor(private router: Router, private storageService:StorageService, 
        private authService:AuthService, private boUsersService: BoUsersService){}

    ngOnInit(): void {
        this.getUserInfo();        
    }

    getUserInfo(){
        if(this.authService.isAuthenticated()) {
            let currentUser=this.storageService.getUser();
            this.boUsersService.getUserInfo(currentUser).then(res => {

                let data:any=res;
                this.userInfo = data.data;
            }).catch(err => {

            });
        }
    }

    onSignout(){
        this.storageService.setSessionId("");
        this.router.navigate(["/auth/login"]);
    }
}

